import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0090 = (props: Props): JSX.Element => {
  const routePram = useRouteParam('https://www.kintaicloud.jp/')
  const routePram2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routePram3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0071/'
  )
  const routePram4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0070/'
  )
  const routePram5 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="無料で使える勤怠管理アプリとは？メリットや注意点も紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.02.22</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0090.jpg"
          alt="キンクラコラム画像90"
        />
        <p>
          勤怠管理システムの導入を検討しているが、導入コストをなるべく抑えたいという企業は多いでしょう。特に、リモートワークを採用している企業では、従業員が
          <span>自宅からも打刻可能なシステム</span>
          を求めているのではないでしょうか。
          <br />
          そこで、おすすめなのが無料で使える勤怠管理アプリです。
          <br />
          では、勤怠管理アプリとはどういうものか？勤怠管理アプリを導入することでどのようなメリットがあるのか？をこの記事で紹介します。
        </p>
        <div id="toc1">
          <h1>無料の勤怠管理アプリとは </h1>
        </div>
        <p>
          従来のタイムカードやPCによる打刻ではなく、個人のスマートフォンから勤怠入力を行えるアプリで、通常の勤怠管理システムと一緒に提供されているケースが多いです。
          <br />
          無料で提供している勤怠管理アプリもあり、中小企業にとっては、
          <span>導入コストを抑えやすい</span>メリットがあります。
        </p>
        <a href={routePram} className="column-detail-link">
          無料で全機能がお試しできるクラウド型勤怠管理システム「キンクラ」とは
        </a>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc2">
          <h1>無料の勤怠管理アプリを利用するメリット</h1>
        </div>
        <img
          src="/images/column/details/c0090_g01.jpg"
          alt="キンクラコラム画像90"
        />
        <p>
          無料の勤怠管理アプリを活用することにより、以下のメリットが期待できます。
        </p>
        <div id="toc2-1">
          <h2>無料のため、コストを気にせずに試験導入しやすい</h2>
        </div>
        <p>
          無料のため、コストを気にせずに<span>試験的に導入</span>
          することが出来ます。
          <br />
          これにより、今まで勤怠管理アプリを使用したことがない従業員でも、使用感を確かめることが可能となります。勤怠管理システムを導入する際に注意しなければならないのが、「自社に合った勤怠管理システムを導入する」ことです。勤怠管理システムを導入してみたものの、自社に合わなかった場合は、無駄なコストが発生してしまうからです。
          <br />
          しかし、無料の勤怠管理アプリであれば、コストを気にせずに試験導入ができるため、そのようなリスクを避けることが出来ます。
        </p>
        <div id="toc2-2">
          <h2>利便性が高い</h2>
        </div>
        <p>
          勤怠管理アプリは、スマホから打刻ができるため、
          <span>オフィスから離れた場所でも打刻が可能</span>
          です。そのため、テレワークや出張先でも勤怠管理が可能です。
          <br />
          また、遅刻・早退・休暇等の申請や承認もアプリから行えるため、管理者の手間を大幅に削減することが出来ます。
        </p>
        <a href={routePram2} className="column-detail-link">
          関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <div id="toc2-3">
          <h2>打刻機などが不要なためコストを抑えることが出来る</h2>
        </div>
        <p>
          個人がすでに持っているスマホで勤怠管理ができるため、打刻機を導入する必要がありません。そのため、打刻機の導入にかかるコストなどを抑えることが出来ます。
          <br />
          また、それ以外にもICカードやタイムカード等も不要になるため、それらにかかっていたコストを抑えることも可能です。
        </p>
        <a href={routePram3} className="column-detail-link">
          勤怠管理システムの費用対効果を最大限にできるコストの見直しとは？
        </a>
        <div id="toc3">
          <h1>無料の勤怠管理アプリを利用する際の注意点</h1>
        </div>
        <p>
          無料の勤怠管理アプリを利用する際には、いくつかの注意点があります。
        </p>
        <div id="toc3-1">
          <h2>使える機能に制約がある</h2>
        </div>
        <p>
          勤怠管理アプリは便利である反面、使える機能に制約があります。
          <br />
          たとえば、利用できる人数に制限があったり、データの保存期間や容量など無料でできる範囲には限界があります。ただし、無料ではなく有料オプションとして機能が提供されている場合があるため、必要な機能があった場合には、料金を支払うことで機能を拡張可能です。
        </p>
        <div id="toc3-2">
          <h2>企業規模の拡大により管理が難しくなる</h2>
        </div>
        <p>
          企業規模が拡大すると、使用人数が増えるだけではありません。就業規則で決められている範囲内で、それぞれの従業員に合った勤務形態を設定する必要があります。
          <br />
          無料の勤怠管理アプリの場合は、小規模な企業を想定している場合が多く、従業員ごとの勤務形態設定自体も多くはありません。そのため、今まで使用できていた勤怠管理アプリが使用できないケースも出てくるでしょう。
        </p>
        <a href={routePram4} className="column-detail-link">
          関連記事：Excel(エクセル)でできる勤怠管理の限界とシステム導入で得られるメリットとは？
        </a>
        <div id="toc3-3">
          <h2>自社システムと連携可能かどうか確認が必要</h2>
        </div>
        <p>
          全ての勤怠管理アプリが、自社が使用している給与管理ソフトや請求書発行システムと連携できるとは限りません。そのため、
          <span>事前にシステム連携ができるかどうか確認しておくこと</span>
          が必要です。
          <br />
          試用期間を活用して、実際に自社システムと連携できるかどうかを確認しておくこともおすすめです。
        </p>
        <div id="toc3-4">
          <h2>アプリ特有の更新が必要</h2>
        </div>
        <p>
          労働法の改正やセキュリティシステムなど、アプリの自動更新が必要です。とくに難しい操作は不要ですが、更新中は勤怠管理アプリが一時的に使用できない状態になるため注意が必要です。
          <br />
          また、更新を忘れると改正法に対応できない可能性があるため、自動更新設定をしておくなどの対応をしておきましょう。
          <br />
          <br />
          無料のアプリは、使える機能に制約があったり、企業規模の拡大により管理内容に限界があることもあります。また、自社システムとの連携状況の確認が必要であり、アプリ特有の更新も必要です。
        </p>
        <div id="toc4">
          <h1>無料の勤怠管理アプリには「キンクラ」がおすすめ</h1>
        </div>
        <p>
          無料の勤怠管理アプリは、従業員が場所にとらわれることなくリアルタイムで勤務状況を報告でき、効率的な勤怠管理を実現する強力なツールです。しかし、その利用には正しい給与計算ができるというメリットだけでなく、自社システムと連携可能かどうかなど注意点も存在します。
          <br />
          これらの点を理解し、自社のニーズに最適なアプリを選ぶことが重要です。
          <br />
          <br />
          <span>そこでおすすめしたいのが「キンクラ」です。</span>
          <br />
          キンクラは、全機能が無料でお試しできるトライアルがあります。自社に必要な機能を知るためにも、トライアルを活用し効果を実感してみてください。
        </p>
        <a href={routePram5} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0090
